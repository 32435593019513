import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import logo from "../../assets/img/vector/main-logo.svg";
import { NavLink, Outlet, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleFilter } from "../../Redux/slices/filterSlice";

import { IoPeople, IoCart, IoFilterSharp } from "react-icons/io5";

import stl from "./layout.module.css";

function Layout() {
	const { totalEmployees, cards } = useSelector((store) => store.cardSlice);
	const { isFiltered } = useSelector((store) => store.filterSlice);
	const location = useLocation();
	const dispatch = useDispatch();
	const toolbarRef = useRef(null);
	const navigationRef = useRef(null);

	/*Filter button properties*/
	const [filterButtonState, setFilterButtonState] = useState(false);
	const filterButton = useRef();
	const filteredAnimation = {
		backgroundColor: ["rgba(216, 250, 255, 1)", "rgba(0, 255, 136, .7)"],
		color: ["#000", "#000"],
		// borderColor: ["rgba(206, 214, 224, 1)", "rgba(33, 150, 243, 1)"],
	};
	const nonFilteredAnimation = {
		backgroundColor: ["rgba(0, 255, 136, .7)", "rgba(216, 250, 255, 1)"],
		color: ["#000", "#000"],
		// borderColor: ["rgba(33, 150, 243, 1)", "rgba(206, 214, 224, 1)"],
	};
	const animationProps = {
		duration: 300,
		iterations: 1,
		fill: "forwards",
		easing: "ease",
	};

	/*nav properties*/
	const cartCounter = useSelector(
		(store) => store.cartSlice.selectedStaff.length
	);

	// const [mainTopPadding, setMainTopPadding] = useState(0);
	const [mainBottomPadding, setMainBottomPadding] = useState(0);

	useLayoutEffect(() => {
		function updatePadding() {
			// setMainTopPadding(toolbarRef.current.clientHeight);
			setMainBottomPadding(navigationRef.current.clientHeight);
		}

		updatePadding();

		window.addEventListener("resize", updatePadding);
		return () => window.removeEventListener("resize", updatePadding);
	}, []);

	useEffect(() => {
		if (filterButtonState) {
			filterButton.current.animate(
				isFiltered ? filteredAnimation : nonFilteredAnimation,
				animationProps
			);
		} else {
			setFilterButtonState(true);
		}
	}, [isFiltered]);

	return (
		<>
			{/* <section className={stl.toolbar} ref={toolbarRef}>
				<Link
					to="/"
					className={stl.logo}
					style={{ backgroundImage: `url(${logo}` }}></Link>
				{location.pathname === "/" ? (
					<span>
						{cards.length}/{totalEmployees}
					</span>
				) : null}
			</section> */}

			<main
				style={{
					// paddingTop: mainTopPadding,
					paddingBottom: mainBottomPadding,
				}}>
				<Outlet />
			</main>

			<nav className={stl.navigation} ref={navigationRef}>
				<NavLink to="/" data-link="home">
					<IoPeople /> <span>Staff</span>					
				</NavLink>
				{location.pathname === "/" ? (
						<div
							className={stl.filterBtn}
							onClick={() => dispatch(handleFilter())}
							ref={filterButton}>
							<IoFilterSharp />
							<span>Filter</span>
						</div>
					) : (
						<></>
					)}
				<NavLink to="/cart" data-link="cart">
					<IoCart /> <span>Cart</span>
					{cartCounter > 0 && <s>{cartCounter}</s>}
				</NavLink>
			</nav>
		</>
	);
}

export { Layout };
